<template>
    <div id="mc-footer" class="container-fluid">
        <!-- Content -->
        <section class="row text-left p-5">
            <!-- Information -->
            <div class="col-12 col-lg-4 px-0 px-sm-5">
                <div style="max-width: 350px;">
                    <img :src="gif_mac_coy" class="img-fluid mb-4 pr-5">
                </div>
                <div class="py-2">
                    <span class="mc--description-sm">Calle San Miguel 424</span>
                </div>
                <div class="py-2">
                    <span class="mc--description-sm">Colonia Jardines de los Arcos</span>
                </div>
                <div class="py-2">
                    <span class="mc--description-sm">Guadalajara Jalisco</span>
                </div>
                <div class="py-2">
                    <span class="mc--description-sm">3868 4454</span>
                </div>
                <div class="py-2">
                    <span class="mc--description-sm">3691 5912</span>
                </div>
                <div class="py-2">
                    <span class="mc--description-sm">3860 4331</span>
                </div>
                <div class="py-2">
                    <span class="mc--description-sm">contacto@concretosmac-coy.com</span>
                </div>
            </div>

            <!-- Menú -->
            <div class="col-6 col-sm-6 col-lg-3 d-flex align-items-start px-0 px-sm-5 py-5 py-lg-0">
                <div>
                    <div class="py-2 mb-3">
                        <span class="mc--description-sm montserrat-bold">MENÚ</span>
                    </div>
                    <div class="py-2">
                        <a href="/#cm_video_main">
                            <span class="span-link mc--description-sm">INICIO</span>    
                        </a>
                    </div>
                    <div class="py-2">
                        <a href="/#mc-weare">
                            <span class="span-link mc--description-sm">SOMOS</span>    
                        </a>
                    </div>
                    <div class="py-2">
                        <a href="/#mc-service">
                            <span class="span-link mc--description-sm">SERVICIO</span>    
                        </a>
                    </div>
                    <div class="py-2">
                        <a href="/#mc-products">
                            <span class="span-link mc--description-sm">PRODUCTOS</span>    
                        </a>
                    </div>
                    <div class="py-2">
                        <a href="/#mc-contact">
                            <span class="span-link mc--description-sm">CONTACTO</span>    
                        </a>
                    </div>
                </div>
            </div>

            <!-- Legal -->
            <div class="col-6 col-sm-6 col-lg-3 d-flex align-items-start px-0 px-sm-5 py-5 py-lg-0">
                <div>
                    <div class="py-2 mb-3">
                        <span class="mc--description-sm montserrat-bold">LEGAL</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('privacy')" class="span-link mc--description-sm cursor-pointer">AVISO DE PRIVACIDAD</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('terms')" class="span-link mc--description-sm cursor-pointer">TÉRMINOS Y CONDICIONES</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('cookies')" class="span-link mc--description-sm cursor-pointer">POLÍTICA DE COOKIES</span>
                    </div>
                    <!-- <div class="py-2">
                        <span @click="redirect('technical_support')" class="span-link mc--description-sm">SOPORTE</span>
                    </div> -->
                </div>
            </div>

            <!-- Social Media -->
            <div class="col-12 col-lg-2 d-flex justify-content-start align-items-start px-0 px-sm-5">
                <div>
                    <div class="">
                        <span class="mc--description-sm montserrat-bold">SÍGUENOS</span>
                    </div>
                    <div class="pt-4">
                        <a href="https://www.facebook.com/ConcretosMacCoyoficial" target="_blank">
                            <img :src="img_fb" class="img-sociality m-1">
                        </a>
                        <a href="https://twitter.com/concretosmaccoy" target="_blank">
                            <img :src="img_tw" class="img-sociality m-1">
                        </a>
                        <a href="https://www.instagram.com/concretosmaccoy1/?igshid=13zmz3eu6ny87" target="_blank">
                            <img :src="img_in" class="img-sociality m-1">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <!-- Line back -->
        <section class="line-back row d-flex justify-content-end">
            <div class="col-12 col-sm-6 col-lg-8 d-flex justify-content-start align-items-center pl-3 pl-sm-5 py-2 py-sm-4 py-lg-4">
                <span class="mc--description-sm montserrat-bold">
                    © 2022 CONCRETOS MAC-COY. TODOS LOS DERECHOS RESERVADOS    
                </span>
            </div>
            <div class="col-8 col-sm-6 col-lg-4 px-0 px-sm-5 pt-sm-4 pt-md-3 pt-lg-0 px-xl-5">
                <!-- <img :src="gif_materialized" class="img-fluid"> -->
            </div>
        </section>
    </div>
</template>

<script>
    // Images
    import img_fb from '../assets/images/footer/icono-fb.png'
    import img_tw from '../assets/images/footer/icono-tw.png'
    import img_in from '../assets/images/footer/icono-ig.png'
    import gif_mac_coy from '../assets/images/footer/logo-footer.png'
    // import gif_materialized from '../assets/gif/materialized-negro.gif'
    

    export default {
        name: 'mc-footer',
        props: {
            msg: String
        },
        data() {
            return {
                img_fb,
                img_tw,
                img_in,
                gif_mac_coy,
                // gif_materialized
            }
        },
        methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            }
        },
    }
</script>

<style scoped lang="scss">
    #mc-footer {
        background-color: #white;

        .img-sociality {
            max-width: 37px;
        }
        
        .span-link {
            
        }
        .span-link:hover {
            text-decoration: underline;
            text-decoration-style: double;
        }

        .line-back {
            background-color: #ededed;
        }
    }
</style>
