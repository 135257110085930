<template>
    <div>
        <!-- Navbar -->
        <b-navbar id="mc_navbar" toggleable="lg" type="light" variant="transparent" class="all_transition_slow fixed-top pl-sm-5 py-1">
            <b-navbar-brand id="cm_navbar__title" @click="redirect('home')" class="mr-3 mr-lg-5" style="max-width: 120px; cursor: pointer;">
                <img :src="gif_logo" class="img-fluid">
            </b-navbar-brand>

            <b-collapse id="nav-text-collapse" is-nav>
                <!-- <b-navbar-nav @click="redirect('home')" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text>Inicio</b-nav-text>
                </b-navbar-nav> -->
            </b-collapse>

            <span class="mc--description-sm text-white mr-3">
                <a href="https://api.whatsapp.com/send?phone=+523311405025&text=Hola%20Concretos%20Mac-Coy" target="_blank" class="mc--description-sm text-white align-middle">
                    <img :src="img_wpp" class="img-fluid align-middle mr-1" style="max-width: 24px !important;">
                    33 1140 5025
                </a>
            </span>
            <b-button v-b-toggle.sidebar-right variant="link">
                <b-icon id="mc_navbar__menu_icon" icon="list" scale="2" variant="light" class="mt-1"></b-icon>
            </b-button>
        </b-navbar>

        <!-- Sidebar -->
        <b-sidebar id="sidebar-right" bg-variant="secondary" class="cm_sidebar" right backdrop shadow>

            <div class="cm_item border-top border-bottom text-black py-3">
                <a href="/#cm_video_main">
                    <span class="span-link mc--description-sm">INICIO</span>
                </a>
            </div>
            <div class="cm_item border-top border-bottom text-black py-3">
                <a href="/#mc-weare">
                    <span class="span-link mc--description-sm">SOMOS</span>
                </a>
            </div>
            <div class="cm_item border-top border-bottom text-black py-3">
                <a href="/#mc-service">
                    <span class="span-link mc--description-sm">SERVICIO</span>
                </a>
            </div>
            <div class="cm_item border-top border-bottom text-black py-3">
                <a href="/#mc-products">
                    <span class="span-link mc--description-sm">PRODUCTOS</span>    
                </a>
            </div>
            <div class="cm_item border-top border-bottom text-black py-3">
                <a href="/#mc-contact">
                    <span class="span-link mc--description-sm">CONTACTO</span>
                </a>
            </div>

            
                

            <div class="row pt-5">
                <div class="col-12 p-5">
                    <div style="max-width: 120px;">
                        <!-- <img :src="gif_premier_mixed" class="img-fluid mb-3"> -->
                    </div>
                    <span class="mc--description-sm text-white">
                        © 2022 Concretos Mac-Coy. Todos los derechos reservados
                    </span>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    // Images
    // import img_cell from '../assets/images/navbar/cellphone.png'
    // import img_mail from '../assets/images/navbar/mail.png'
    // import img_fb from '../assets/images/navbar/facebook.png'
    // import img_tw from '../assets/images/navbar/twitter.png'
    import img_wpp from '../assets/images/navbar/icono-whats.png'
    // Gifs
    import gif_logo from '../assets/gifs/navbar/logo-mccoy-animacion.gif'
    // jQuery
    import $ from 'jquery'

    export default {
        name: 'mc-navbar',
        props: {
            msg: String
        },
        data() {
            return {
                // img_cell,
                // img_mail,
                // img_fb,
                // img_tw,
                img_wpp,
                gif_logo
            }
        },
        methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            }
        },
        created() {
            $(window).scroll(function() {
                console.log(`scroll: ${$(document).scrollTop()}`)

                if($(document).scrollTop() > ($('#mc_navbar').height() + 8)) {
                    $('#mc_navbar').removeClass('bg-transparent');
                    $('#mc_navbar').addClass('cm_navbar__scroll');
                    // $('#pr_navbar').addClass('fixed-top');
                    // $('#pr_navbar').removeClass('fixed-top-custom');
                }
                else {
                    $('#mc_navbar').addClass('bg-transparent');
                    $('#mc_navbar').removeClass('cm_navbar__scroll');
                    // $('#pr_navbar').addClass('fixed-top-custom');
                    // $('#pr_navbar').removeClass('fixed-top');
                }
            })
        }
    }
</script>

<style lang="scss">

    #cm_navbar {
        -webkit-transition: all 0.6s ease;
        transition: all 0.6s ease;
    }

    .cm_navbar__scroll {
        background-color: #45454599;
        box-shadow: 1px 4px 26px 5px rgba(0,0,0,0.71);
        -webkit-box-shadow: 1px 4px 26px 5px rgba(0,0,0,0.71);
        -moz-box-shadow: 1px 4px 26px 5px rgba(0,0,0,0.71);
    }

    .cm_sidebar {

        .cm_item {
            cursor: default;

            span {
                font-family: 'montserrat-Black';
                color: white !important;
            }
            span:hover {
                text-decoration: underline;
                text-decoration-style: double;
            }
        }
    }

    .b-sidebar {
        width: 400px !important;

        .b-sidebar-header {

            button {
                color: white !important;

                svg {
                    font-size: 40px;
                }
            }
        }
    }
</style>
