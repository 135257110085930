var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",attrs:{"id":"mc-footer"}},[_c('section',{staticClass:"row text-left p-5"},[_c('div',{staticClass:"col-12 col-lg-4 px-0 px-sm-5"},[_c('div',{staticStyle:{"max-width":"350px"}},[_c('img',{staticClass:"img-fluid mb-4 pr-5",attrs:{"src":_vm.gif_mac_coy}})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_vm._m(7),_c('div',{staticClass:"col-6 col-sm-6 col-lg-3 d-flex align-items-start px-0 px-sm-5 py-5 py-lg-0"},[_c('div',[_vm._m(8),_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"span-link mc--description-sm cursor-pointer",on:{"click":function($event){return _vm.redirect('privacy')}}},[_vm._v("AVISO DE PRIVACIDAD")])]),_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"span-link mc--description-sm cursor-pointer",on:{"click":function($event){return _vm.redirect('terms')}}},[_vm._v("TÉRMINOS Y CONDICIONES")])]),_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"span-link mc--description-sm cursor-pointer",on:{"click":function($event){return _vm.redirect('cookies')}}},[_vm._v("POLÍTICA DE COOKIES")])])])]),_c('div',{staticClass:"col-12 col-lg-2 d-flex justify-content-start align-items-start px-0 px-sm-5"},[_c('div',[_vm._m(9),_c('div',{staticClass:"pt-4"},[_c('a',{attrs:{"href":"https://www.facebook.com/ConcretosMacCoyoficial","target":"_blank"}},[_c('img',{staticClass:"img-sociality m-1",attrs:{"src":_vm.img_fb}})]),_c('a',{attrs:{"href":"https://twitter.com/concretosmaccoy","target":"_blank"}},[_c('img',{staticClass:"img-sociality m-1",attrs:{"src":_vm.img_tw}})]),_c('a',{attrs:{"href":"https://www.instagram.com/concretosmaccoy1/?igshid=13zmz3eu6ny87","target":"_blank"}},[_c('img',{staticClass:"img-sociality m-1",attrs:{"src":_vm.img_in}})])])])])]),_vm._m(10)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('span',{staticClass:"mc--description-sm"},[_vm._v("Calle San Miguel 424")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('span',{staticClass:"mc--description-sm"},[_vm._v("Colonia Jardines de los Arcos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('span',{staticClass:"mc--description-sm"},[_vm._v("Guadalajara Jalisco")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('span',{staticClass:"mc--description-sm"},[_vm._v("3868 4454")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('span',{staticClass:"mc--description-sm"},[_vm._v("3691 5912")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('span',{staticClass:"mc--description-sm"},[_vm._v("3860 4331")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('span',{staticClass:"mc--description-sm"},[_vm._v("contacto@concretosmac-coy.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 col-sm-6 col-lg-3 d-flex align-items-start px-0 px-sm-5 py-5 py-lg-0"},[_c('div',[_c('div',{staticClass:"py-2 mb-3"},[_c('span',{staticClass:"mc--description-sm montserrat-bold"},[_vm._v("MENÚ")])]),_c('div',{staticClass:"py-2"},[_c('a',{attrs:{"href":"/#cm_video_main"}},[_c('span',{staticClass:"span-link mc--description-sm"},[_vm._v("INICIO")])])]),_c('div',{staticClass:"py-2"},[_c('a',{attrs:{"href":"/#mc-weare"}},[_c('span',{staticClass:"span-link mc--description-sm"},[_vm._v("SOMOS")])])]),_c('div',{staticClass:"py-2"},[_c('a',{attrs:{"href":"/#mc-service"}},[_c('span',{staticClass:"span-link mc--description-sm"},[_vm._v("SERVICIO")])])]),_c('div',{staticClass:"py-2"},[_c('a',{attrs:{"href":"/#mc-products"}},[_c('span',{staticClass:"span-link mc--description-sm"},[_vm._v("PRODUCTOS")])])]),_c('div',{staticClass:"py-2"},[_c('a',{attrs:{"href":"/#mc-contact"}},[_c('span',{staticClass:"span-link mc--description-sm"},[_vm._v("CONTACTO")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2 mb-3"},[_c('span',{staticClass:"mc--description-sm montserrat-bold"},[_vm._v("LEGAL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('span',{staticClass:"mc--description-sm montserrat-bold"},[_vm._v("SÍGUENOS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"line-back row d-flex justify-content-end"},[_c('div',{staticClass:"col-12 col-sm-6 col-lg-8 d-flex justify-content-start align-items-center pl-3 pl-sm-5 py-2 py-sm-4 py-lg-4"},[_c('span',{staticClass:"mc--description-sm montserrat-bold"},[_vm._v(" © 2022 CONCRETOS MAC-COY. TODOS LOS DERECHOS RESERVADOS ")])]),_c('div',{staticClass:"col-8 col-sm-6 col-lg-4 px-0 px-sm-5 pt-sm-4 pt-md-3 pt-lg-0 px-xl-5"})])
}]

export { render, staticRenderFns }