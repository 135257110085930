<template>
    <div id="app">
        <mc-navbar></mc-navbar>
        <router-view/>
        <mc-footer></mc-footer>
    </div>
</template>

<script>
    // Components
    import Navbar from './components/mc-navbar.vue'
    import Footer from './components/mc-footer.vue'

    export default {
        name: 'app_main',
        components: {
            'mc-navbar': Navbar,
            'mc-footer': Footer
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: Lato-Regular;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100% !important;
        text-align: center;
    }
</style>
