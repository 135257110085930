import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('../views/Terms.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/Privacy.vue')
    },
    {
        path: '/cookies',
        name: 'cookies',
        component: () => import('../views/Cookies.vue')
    },
    { path: '*', beforeEnter: (to, from, next) => { next('/404') } },
    {
        path: '/404',
        name: '404',
        component: () => import('../components/mc-page_not_found.vue')
    }
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
