<template>
    <div id="cm_home" class="container-fluid">
    	<!-- [Main] video main -->
    	<section id="cm_video_main" class="row section__index d-flex px-0">
			<video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
				<source :src="video_main" type="video/mp4">
			</video>

            <div id="s1_vid_target_1" style="width: 100%; position: absolute;">
                <div id="s1_vid_row_target_1" class="row">
                    <div class="col-12 d-flex justify-content-start align-items-end align-items-sm-center">
                        <div data-aos="fade-right" class="text-left pl-2 pl-lg-5" style="max-width: 640px;">
                            <span class="mc--title montserrat-bold text-white"> CONSTRUYENDO EL FUTURO 
                                <span class="text-warning"> DE MÉXICO </span>
                            </span> <br>
                            <div class="d-none d-md-flex mt-3">
                                <span class="mc--description montserrat-regular text-white">
                                    ALTA EXPERIENCIA EN CONCRETOS
                                </span> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</section>


		<!-- BackgroundImage Cards -->
		<section class="row" style="background-size: cover;" :style="{ backgroundImage: 'url(' + s2_img_background + ')' }">
			<div class="col-12">
        		<div class="row bg-images-to-top d-flex justify-content-center align-items-start p-0">
	                <div class="col-12 col-md col-lg-3 mb-4">
	                	<img :src="s2_img_1" data-aos="fade-up" class="img-fluid mb-4">
	                	
	                    <div data-aos="fade-up" data-aos-delay="500">
	                    	<center>
	                    		<div style="max-width: 250px;">
	                                <span class="mc--description montserrat-bold text-white">ALTA EXPERIENCIA EN LA INDUSTRIA</span>
	                            </div>	
	                    	</center>
                        </div>
	                </div>
	                <div data-aos="fade-up" class="col-12 col-md col-lg-3 mb-4">
	                    <img :src="s2_img_2" data-aos="fade-up" class="img-fluid mb-4">

	                    <div data-aos="fade-up" data-aos-delay="500">
	                    	<center>
	                    		<div style="max-width: 250px;">
	                                <span class="mc--description montserrat-bold text-white">CONCRETO DE ALTA RESISTENCIA</span>
	                            </div>	
	                    	</center>
                        </div>
	                </div>
	                <div data-aos="fade-up" class="col-12 col-md col-lg-3 mb-4">
	                    <img :src="s2_img_3" data-aos="fade-up" class="img-fluid mb-4">

	                    <div data-aos="fade-up" data-aos-delay="500">
	                    	<center>
	                    		<div style="max-width: 250px;">
	                                <span class="mc--description montserrat-bold text-white">CAPACIDAD PARA TODO TIPO DE PROYECTO</span>
	                            </div>	
	                    	</center>
                        </div>
	                </div>
	                
	                <img id="img_ref_1" :src="s2_img_background" class="img-fluid" style="position: absolute; top: -10000px;">
	            </div>	
        	</div>
        </section>


        <!-- BackgroundVideo Informative -->
        <section class="row px-0">
            <video id="vid_ref_1" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" class="video-fluid">
                <source :src="s3_vid_background" type="video/mp4">
            </video>

            <div id="vid_target_1" style="width: 100%; position: absolute;">
                <div id="vid_row_target_1" class="row">
                    <div class="col-12 d-flex justify-content-center align-items-center">
                        <center>
                            <div class="d-none d-md-flex mb-3 mb-lg-5" style="max-width: 400px;">
                                <img :src="s4_gif_1" class="img-fluid px-5">
                            </div>
                            <div data-aos="fade-up" style="max-width: 740px;">
                                <span class="mc--title montserrat-bold text-white">
                                    CONSTRUYENDO 
                                </span> <br>
                                <span class="mc--title montserrat-regular text-white">
                                    LAS CIUDADES DEL 
                                </span> 
                                <span class="mc--title montserrat-bold text-warning">
                                    FUTURO
                                </span>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </section>


        <!-- [WeAre] Yellow Video - informative -->
        <section id="mc-weare" class="row">
        	<div class="col-12 col-lg-6 d-flex justify-content-center align-items-center py-5">
                <div class="mb-5" style="max-width: 600px;">
                    <img id="img_ref_2" :src="s4_img_1" class="img-fluid">

                    <video id="img_target_2" data-aos="fade-left" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" class="video-fluid video-yellow">
		                <source :src="s3_vid_1" type="video/mp4">
		            </video>
                </div>
        	</div>
        	<div class="col-12 col-lg-6 pb-5">
        		<div class="row text-left px-0 px-md-5">
        			<div class="col-12 pt-5">
        				<img :src="s4_img_2" data-aos="fade-right" class="img-fluid mb-5">

        				<div data-aos="fade-up" style="max-width: 600px;">
		        			<span class="mc--title montserrat-bold">EMPRESA 100% <span class="text-warning">MEXICANA</span> </span>	
		        		</div>
        			</div>
        			<div class="col-12 d-flex justify-content-start align-items-start text-left pt-2 pt-xl-4">
        				<div data-aos="fade-up" data-aos-delay="500" style="max-width: 430px;">
		        			<span class="mc--description">
	        					Somos una empresa 100% mexicana especializada en ofrecer concreto premezclado de la más alta calidad y resistencia.
	        					<br><br>

	        					Contamos con una gran capacidad instalada para ofrecer proyectos de todas magnitudes, entregando en tiempo y forma.
	        				</span>
		        		</div>
        			</div>
        		</div>
        	</div>
        </section>


        <!-- [Services] BackgroundImage Informative -->
        <section id="mc-service" class="row" style="background-size: cover;" :style="{ backgroundImage: 'url(' + s2_img_background + ')' }">
        	<div class="col-12 col-lg-6 d-flex justify-content-center align-items-center py-5">
                <div data-aos="fade-right" class="ml-3 ml-lg-4" style="max-width: 850px;">
        			<span class="mc--title">
        				<span class="montserrat-bold text-white">
        					CONTACTANOS HOY MISMO Y	
        				</span>
        				<span class="text-warning">
        					COMENCEMOS TU PROYECTO
        				</span>
        			</span>
        		</div>
        	</div>
        	<div class="col-12 col-lg-6 d-flex justify-content-end align-items-center pb-5 pb-lg-0">
        		<div data-aos="fade-left" class="text-right mr-3 mr-lg-5" style="max-width: 450px;">
        			<span>
                        <a href="https://api.whatsapp.com/send?phone=+523311405025&text=Hola%20Concretos%20Mac-Coy" target="_blank" class="mc--description-sm text-white align-middle">
                            <img :src="img_wpp" class="align-middle mr-3">
                            <span class="mc--title text-white align-middle">33 1140 5025</span>
                        </a>
	                </span>
	                <div class="mt-3">
	                	<span class="mc--description montserrat-bold text-white">
		                	contacto@concretosmac-coy.com
		                </span>	
	                </div>
        		</div>
        	</div>
        </section>


        <!-- BackgroundImage Informative -->
        <section class="row" style="background-size: cover;" :style="{ backgroundImage: 'url(' + s5_img_background + ')' }">
        	<div class="col-12 col-lg-7 d-flex justify-content-center align-items-center py-5">
                <div class="text-left ml-3 ml-lg-4 p-0 p-lg-5" style="max-width: 850px;">
                	<img :src="s5_img_1" data-aos="fade-right" class="align-bottom mb-4 mr-1"> <br>

                    <div data-aos="fade-right">
                        <span class="mc--title">
                            <span class="montserrat-bold text-white">
                                PERSONAL CAPACITADO Y DE ALTA
                            </span>
                            <span class="text-warning">
                                ESPECIALIZACIÓN
                            </span>
                        </span>
                    </div>

        			<div data-aos="fade-up" data-aos-delay="500" class="mt-5" style="max-width: 520px;">
        				<span class="mc--description text-white">
	        				Nuesto personal cuenta con alto grado de
	        				especialización y se encuentra en una
	        				constante capacitación en todas las áreas,
	        				asegurando así la calidad en cada uno de 
	        				los proyectos.
	        			</span>
        			</div>
        		</div>
        	</div>
        </section>


        <!-- [Products] Cols of Images -->
        <section id="mc-products" class="row d-flex justify-content-center align-items-center py-5">
            <div class="col-12 col-lg-10 px-0">
                <div class="row">
                	<div data-aos="fade-up" class="col-12 d-flex justify-content-center">
                		<img :src="s6_img_0" class="img-fluid mb-5"> <br>
                	</div>

					<div data-aos="fade-up" data-aos-delay="400" class="col-12 d-flex justify-content-center">
						<div style="max-width: 1000px;">
							<span class="mc--title">
								<span class="montserrat-bold">
									CONCRETO DE ACUERDO A LAS NECESIDADES
								</span>
								<span class="text-warning">
									DE CADA PROYECTO
								</span>
							</span>
						</div>
					</div>

                    <div data-aos="fade-up" class="col-12 col-md-6 d-flex justify-content-center align-items-center container-show p-2 mt-5"
                        v-for="(item, item_index) in img_cols" :key="item_index">
                        <img :src="item.img" class="img-fluid">

                        <div style="width: 100%; position: absolute;">
	                        <div class="row">
	                            <div class="col-12">
	                                <center>
	                                    <div data-aos="fade-up" data-aos-delay="400" style="max-width: 440px;">
	                                        <span class="mc--description montserrat-regular text-white" v-if="item.title !== null">
	                                        	{{ item.title }}
	                                        </span> <br>
	                                        <span class="mc--description montserrat-bold text-white" v-if="item.subtitle !== null">
	                                        	{{ item.subtitle }}
	                                        </span>
	                                    </div>
	                                    <div data-aos="fade-up" data-aos-delay="500">
	                                    	<div class="show-only-hover mt-3" style="max-width: 350px;">
                                                <span class="mc--description text-white montserrat-bold">
                                                    {{ item.description }}
                                                </span>
                                                <div class="mt-3 justify-content-center d-none d-sm-flex">
                                                    <button @click="redirectInSectionPage('mc-contact')" class="mc-button-yellow all_transition">
                                                        <span class="mc--description-sm">COTIZAR</span>
                                                    </button>    
                                                </div>
                                            </div>
	                                    </div>
	                                </center>
	                            </div>
	                        </div>
	                    </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- Yellow  Video - informative -->
        <section class="row">
        	<div class="col-12 col-lg-6 d-flex justify-content-center align-items-center py-5">
                <div class="mb-5" style="max-width: 600px;">
                    <img id="img_ref_3" :src="s7_img_1" class="img-fluid">

                    <video id="img_target_3" data-aos="fade-left" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" class="video-fluid video-yellow">
		                <source :src="s7_vid_1" type="video/mp4">
		            </video>
                </div>
        	</div>
        	<div class="col-12 col-lg-6 pb-5">
        		<div class="row text-left px-0 px-md-5">
        			<div class="col-12 pt-5">
        				<img :src="s7_img_2" data-aos="fade-right" class="img-fluid mb-5">

        				<div data-aos="fade-up" style="max-width: 200px;">
		        			<span class="mc--title montserrat-bold">
		        				<span>ENORME </span>
		        				<span class="text-warning">CAPACIDAD INSTALADA</span>
		        			</span>
		        		</div>
        			</div>
        			<div data-aos="fade-up" data-aos-delay="500" class="col-12 d-flex justify-content-start align-items-start text-left pt-2 pt-xl-4">
        				<div style="max-width: 430px;">
		        			<span class="mc--description">
	        					En concretos MAC-COY contamos con una enorme capacidad instalada para atender cada uno de nuestros proyectos de manera profesional y personalizada.
	        					<br><br>

	        					De esta manera garantizamos en cada proyecto un servicio de excelente calidad en tiempo y forma.
	        				</span>
		        		</div>
        			</div>
        		</div>
        	</div>
        </section>


        <!-- [Contact] Form contact -->
        <section id="mc-contact" class="row px-0"> <!-- style="background-size: cover; background-position: center;" :style="{ backgroundImage: 'url(' + s8_img_background + ')' }" -->
            <video id="s8_vid_ref_1" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                <source :src="s8_vid_1" type="video/mp4">
            </video>

            <div id="s8_vid_target_1" style="width: 100%; position: absolute;">
                <div id="vid_row_target_1" class="row">
                    <div class="col-12 col-xl-7 d-flex justify-content-center align-items-center py-5">
                        <div class="text-left ml-0 ml-lg-4 p-0 p-xl-5" style="max-width: 800px;" v-if="!sended_email">
                            <div data-aos="fade-up" class="mx-3">
                                <span class="mc--title">
                                    <span class="montserrat-bold text-white">
                                        COMUNÍCATE HOY MISMO
                                    </span> <br>
                                    <span class="text-warning">
                                        Y COMENCEMOS TU PROYECTO
                                    </span>
                                </span>
                            </div>

                            <div data-aos="fade-right" data-aos-delay="500" class="row mt-2 mt-xl-5" style="max-width: 680px;">
                                <div class="col-12 my-2">
                                    <span class="mc--description-sm text-white">NOMBRE</span><br>
                                    <input v-model="form.name" type="text" name="name" class="mc_input full-width"> 
                                </div>

                                <div class="col-12 col-sm-6 my-2">
                                    <span class="mc--description-sm text-white">TELÉFONO</span><br>
                                    <input v-model="form.phone" type="text" name="phone" class="mc_input full-width">   
                                </div>
                                <div class="col-12 col-sm-6 my-2">
                                    <span class="mc--description-sm text-white">EMAIL</span><br>
                                    <input v-model="form.email" type="text" name="email" class="mc_input full-width">   
                                </div>

                                <div class="col-12 col-sm-6 my-2">
                                    <span class="mc--description-sm text-white">CIUDAD</span><br>
                                    <input v-model="form.city" type="text" name="city" class="mc_input full-width"> 
                                </div>
                                <div class="col-12 col-sm-6 my-2">
                                    <span class="mc--description-sm text-white">ESTADO</span><br>
                                    <input v-model="form.federative_entity" type="text" name="federative_entity" class="mc_input full-width">   
                                </div>
                                
                                <div class="col-12 my-2">
                                    <span class="mc--description-sm text-white">MENSAJE</span><br>
                                    <textarea v-model="form.message" rows="4" type="text" name="name" class="mc_input full-width"></textarea>
                                </div>

                                <div class="col-12 text-right my-3">
                                    <button @click="sendMail()" class="mc-button-yellow all_transition">
                                        <span class="mc--description-sm">ENVIAR</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="text-left p-0 p-xl-5" style="max-width: 800px;" v-if="sended_email">
                            <div data-aos="fade-up" class="col-12 px-0 px-md-5 mt-5" v-if="sended_email">
                                <div class="mt-5"> <!-- style="background-color: #222; border-radius: 8px;" -->
                                    <div class="row p-4">
                                        <div class="col-12" style="padding-top: 20px;">
                                            <span class="mc--title text-warning">
                                                ¡Gracias!
                                            </span> <br><br>
                                            <span class="mc--title text-white">
                                                Nos pondremos en contacto contigo a la brevedad.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        	
        </section>


        <!-- BackgroundImage Informative -->
        <section id="img_target_4" class="row" style="background-size: cover;" :style="{ backgroundImage: 'url(' + s9_img_1 + ')' }">
        	<div class="col-10 offset-2 col-sm-6 offset-sm-6 col-lg-7 offset-lg-5 d-flex justify-content-center align-items-center py-0 py-lg-5">
                <div data-aos="fade-up" class="text-left ml-3 ml-lg-4 p-0 p-lg-5" style="max-width: 750px;">
        			<span class="mc--title">
        				<span class="montserrat-bold text-white">
        					EXPERTOS EN CONCRETO 
        				</span>
        				<span class="text-warning">
        					DE ALTA DURABILIDAD
        				</span>
        			</span>
        		</div>
        	</div>

        	<img id="img_ref_4" :src="s9_img_1" class="img-fluid" style="position: absolute; top: -10000px;">
        </section>


        <!-- Button float -->
        <section id="buttons_float" class="row" data-aos="fade-up">
        	<div class="d-flex justify-content-end align-items-center">
        		<a href="#cm_home">
        			<img :src="img_float" class="img-fluid all_transition">
        		</a>
        	</div>
		</section>
	</div>
</template>

<script>
	// Videos
    import video_main from '../assets/videos/home/loop-main.mp4'
    import s3_vid_background from '../assets/videos/home/section3/loop-ciudades.mp4'
    import s3_vid_1 from '../assets/videos/home/section4/loop-somos.mp4'
    import s7_vid_1 from '../assets/videos/home/section7/loop-capacidad.mp4'
    import s8_vid_1 from '../assets/videos/home/section8/loop-formulario.mp4'
    // Images
    import s2_img_background from '../assets/images/home/section2/fondo-concreto.png'
    import s2_img_1 from '../assets/images/home/section2/alta-experiencia.png'
    import s2_img_2 from '../assets/images/home/section2/concreto-alta-resist.png'
    import s2_img_3 from '../assets/images/home/section2/capacidad.png'

    import s4_img_1 from '../assets/images/home/section4/fondo-amarillo.png'
    import s4_img_2 from '../assets/images/home/section4/icono-edificio.png'
    import img_wpp from '../assets/images/home/section4/05-icono-whats.png'

    import s5_img_background from '../assets/images/home/section5/imagen-fondo.png'
    import s5_img_1 from '../assets/images/home/section5/icono-trabajador.png'

    import s6_img_0 from '../assets/images/home/section6/logo-original.png'
    import s6_img_1 from '../assets/images/home/section6/01-concreto-estructural.png'
    import s6_img_2 from '../assets/images/home/section6/02-convencional.png'
    import s6_img_3 from '../assets/images/home/section6/03-mr.png'
    import s6_img_4 from '../assets/images/home/section6/04-lanzado.png'
    import s6_img_5 from '../assets/images/home/section6/05-jalcreto.jpeg'
    import s6_img_6 from '../assets/images/home/section6/06-relleno-fluido.png'

    import s7_img_1 from '../assets/images/home/section7/fondo-amarillo.png'
    import s7_img_2 from '../assets/images/home/section7/icono-vehiculo.png'

	import s9_img_1 from '../assets/images/home/section9/10-expertos-concreto.png'

	// Gifs
	import s4_gif_1 from '../assets/gifs/navbar/logo-mccoy-animacion.gif'

	// Float image
	import img_float from '../assets/images/footer/top-button-flotante.png'	
	
    
    // jQuery
    import $ from 'jquery'
    

	export default {
		name: 'Home',
		components: {
			//HelloWorld
		},
		data() {
			return {
				video_main,
				s3_vid_background,
				s3_vid_1, 
				s7_vid_1, 
                s8_vid_1, 
				s2_img_background,
				s2_img_1, 
				s2_img_2, 
				s2_img_3, 
				s4_img_1, 
				s4_img_2, 
				img_wpp, 
				s5_img_background, 
				s5_img_1, 

				s6_img_0,
				img_cols: [
					{ img: s6_img_1, title: 'CONCRETO', subtitle: 'ESTRUCTURAL', description: `Cuenta con un gran desempeño y resistencia a la compresión, por lo que su principal característica es la resistencia y durabilidad.` },
					{ img: s6_img_2, title: 'CONCRETO', subtitle: 'CONVENCIONAL', description: `Este es utilizado en las estructuras de concreto más comunes. Se emplea para cimentaciones, placas macizas y aligeradas, muros de contención, etc.` },
					{ img: s6_img_3, title: 'MR', subtitle: 'MÓDULOS DE RUPTURA', description: `Este concreto esta diseñado especialmente para resistir esfuerzos a flexión, impuestos por el paso de vehículos en las estructuras de pavimento.` },
					{ img: s6_img_4, title: 'CONCRETO', subtitle: 'LANZADO', description: `Este concreto es aplicado en la  obra por compresión neumática a tráves de un conducto y es proyectado a gran velocidad sobre una superficie requerida.` },
					{ img: s6_img_5, title: null, subtitle: 'JALCRETO', description: `Es un concreto de bajo peso volumétrico. Es una alternativa en la cual se reducen las cargas  sobre una estructura, 
                        mediante un material más ligero en combinación con cemento.` },
					{ img: s6_img_6, title: 'RELLENO', subtitle: 'FLUIDO', description: `El relleno fluido es una material auto-compactante de baja resistencia el cual es de consistencia fluida. Se utiliza como  material de relleno económico.` }
                ],

                s7_img_1, 
                s7_img_2, 

                form: {
                    name: null,
                    phone: null,
                    email: null,
                    city: null,
                    federative_entity: null,
                    message: null
                },
                sended_email: false, 

                s9_img_1,
                s4_gif_1,
                img_float
			}
		},
		methods: {
            redirectInSectionPage(element_id) {
                // console.log(element_id)
                document.getElementById(element_id).scrollIntoView()
            },
            sendMail() {
                this.sended_email = true
                
                fetch('https://mac-coy.blueberry.team/send_email.php', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, /',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        name: this.form.name, 
                        phone: this.form.phone, 
                        email: this.form.email,
                        city: this.form.city,
                        federative_entity: this.form.federative_entity, 
                        message: this.form.message 
                    })
                }).then(
                    res => res.json()
                ).then(
                    res => console.log(res)
                )

                this.sended_email = true;

                $(document).ready(function() {
                    $("body").children().each(function() {
                        $(this).html($(this).html().replace(/&#8232;/g," "))
                    })
                })
            }
        },
		created() {
            $(window).resize(function() {
                let w = 0
                if($('#cm_video_main').width() > 1100) {
                    w = ($('#cm_video_main').width() / 10) * 5
                }
                else {
                    w = ($('#cm_video_main').width() / 10) * 10
                }

                // $("#overlay_main_video").css("width", w)
                // let position_left = 0 
                // $("#overlay_main_video").css("left", position_left)
                // let position_top = (($('#cm_video_main').height() / 10) * 4)
                // $("#overlay_main_video").css("top", position_top)

                // Image main
                let height_ref_1 = $('#img_ref_1').height()
                $("#pr_text_main").css("height", height_ref_1)

                // Yellow
                let height_ref_2 = $('#img_ref_2').height()
                let width_ref_2 = $('#img_ref_2').height()
                $("#img_target_2").css("height", height_ref_2)
                let value_special = ($('#cm_video_main').width() > 714) ? 20 : -20
                $("#img_target_2").css("marginLeft", `-${width_ref_2 - value_special}px`)

                let height_ref_3 = $('#img_ref_3').height()
                let width_ref_3 = $('#img_ref_3').height()
                $("#img_target_3").css("height", height_ref_3)
                $("#img_target_3").css("marginLeft", `-${width_ref_3 - value_special}px`)


				let height_ref_4 = $('#img_ref_4').height()
                $("#img_target_4").css("height", height_ref_4)

                let height_ref_5 = $('#vid_ref_1').height()
                $("#vid_target_1").css("height", height_ref_5)
                $("#vid_row_target_1").css("height", height_ref_5)

                let height_ref_6 = $('#s8_vid_ref_1').height()
                $("#s8_vid_target_1").css("height", height_ref_6)
                $("#s8_vid_row_target_1").css("height", height_ref_6)

                let height_ref_7 = $('#cm_video_main').height()
                $("#s1_vid_target_1").css("height", height_ref_7)
                $("#s1_vid_row_target_1").css("height", height_ref_7)
                

            })
        },
        mounted() {
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 200);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 600);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 1000);
        }
	}
</script>

<style scoped lang="scss">
	#cm_home {
		min-height: 1000px !important;

        .bg-images-to-top {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        @media (min-width: 576px) {
            .bg-images-to-top {
                margin-top: -50px;
                margin-bottom: 100px;
            }   
        }
        

        .img_black_white {
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
        }

        .img_black_white:hover {
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
        }

        .video-yellow {
        	position: absolute; 
        	margin-top: 50px;        	
        }
	}
</style>
